<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="card-statistics">
          <b-card-header class="p-2">
            <b-card-title>Resumo {{ month }}</b-card-title>
          </b-card-header>
          <b-card-body class="px-3">
            <b-row class="justify-content-around justify-content-lg-between flex-wrap">
              <b-col cols="auto" class="mt-1 text-center">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <span class="totalizer-title">Total bruto</span>
                    <br>
                    <h4 class="font-weight-bolder mb-0">
                      {{ totalGross | toCurrency }}
                    </h4>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="auto" class="mt-1 text-center">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <span class="totalizer-title">Total líquido</span>
                    <br>
                    <h4 class="font-weight-bolder mb-0">
                      {{ totalAmount | toCurrency }}
                    </h4>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="auto" class="mt-1 text-center">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <span class="totalizer-title">Valor da taxa</span>
                    <br>
                    <h4 class="font-weight-bolder mb-0">
                      {{ totalTax }}%
                    </h4>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="auto" class="mt-1 text-center">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <span class="totalizer-title">Quantidade de correções</span>
                    <br>
                    <h4 class="font-weight-bolder mb-0">
                      {{ totalImports }}
                    </h4>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaBody, BTable
} from 'bootstrap-vue'
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as types from "../store/types";

export default {
  components: {
    BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaBody, BTable
  },
  props: {
    filters: Object
  },
  data() {
    return {
    }
  },
  setup() {
    return { toast: useToast() };
  },
  computed: {
    ...mapGetters({
      totalizer: types.PAYMENTS_IMPORTS_DETAILS_TOTALIZER,
    }),
    totalGross: function () {
      return this.totalizer?.total_gross || 0
    },
    totalAmount : function () {
      return this.totalizer?.total_amount || 0
    },
    totalTax: function () {
      return this.totalizer?.tax || 0
    },
    totalImports: function () {
      return this.totalizer?.total_month_imports - 1 || 0
    },
    month: function(){
      return this.$route.params.month + '/' + this.$route.params.year
    }
  },
  methods: {
    ...mapActions({
      getTotalizer: types.GET_PAYMENTS_IMPORTS_DETAILS_TOTALIZER
    }),
    search(){
      this.getTotalizer({
        ...this.filters,
        year: parseInt(this.$route.params.year),
        month: parseInt(this.$route.params.month)
      })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text:  "Ocorreu um erro ao carregar o totalizador. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    }
  },
 
}
</script>

<style lang="scss" scoped>
.totalizer-title {
  font-weight: 500;
  font-size: 1.2rem;
}
</style>