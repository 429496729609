<template>
  <b-popover
    :target="target"
    triggers="focus"
    placement="top"
    @show="getData"
    boundary="viewport"
  >
    <template #title>
      <span>{{ name }}</span>
    </template>
    <b-row class="justify-content-md-center">
      <b-spinner v-if="loading" big />
    </b-row>
    <b-row>
      <template v-if="partnerCompany">
        <b-col md="12">
          Empresa: <b>{{ partnerCompany.name }}</b>
        </b-col>
      </template>
      <template v-if="product">
        <b-col md="12">
          Produto: <b>{{ product.name }}</b>
        </b-col>
      </template>
    </b-row>
  </b-popover>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  VBPopover,
  BPopover,
  BCardText,
  BButtonGroup,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import * as types from "../store/types";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { useToast } from "vue-toastification/composition";

export default {
  components: {
    BCardCode,
    BButton,
    BCardText,
    BPopover,
    BButtonGroup,
    BCol,
    BRow,
    BSpinner,
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },
  props: {
    target: {
      type: String,
    },
    id: {
      type: Number,
      default: undefined,
    },
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: true,
      name: undefined,
      product: undefined,
      partnerCompany: undefined,
    };
  },
  methods: {
    ...mapActions({
      getFinancialSolution: types.GET_FINANCIAL_SOLUTION,
    }),
    getData() {
      if (this.id) {
        this.getFinancialSolution(this.id)
          .then((response) => {
            const { id, name, product, partner_company } = response.data;
            this.name = name;
            this.product = product;
            this.partnerCompany = partner_company;
            this.loading = false;
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao recuperar os dados da solução financeira. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    goToDetail() {
      let appify_url = process.env.VUE_APP_URL;
      setTimeout(() => {
        window.open(appify_url + this.detailUrl, "_blank").focus();
      });
    },
    goToWhatsapp() {
      setTimeout(() => {
        window.open("https://wa.me/" + this.phone, "_blank").focus();
      });
    },
  },
};
</script>
